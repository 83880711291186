/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import { CloseXSVG } from '../svg';
import './alert.scss';

const Alert = props => {
  const [state, setState] = useState({ alerts: [], intervalId: '' });

  const createAlert = payload => {
    const { detail } = payload;

    detail.time = new Date();
    const { type } = detail;

    if (!detail.hasOwnProperty('message')) {
      console.error('Alert missing property "message" in event detail');
    } else if (!detail.hasOwnProperty('type')) {
      console.error('Alert missing property "type" in event detail');
    } else if (type !== 'error' && type !== 'success' && type !== 'info' && type !== 'warning') {
      console.error('Alert has a bad type. Make sure the type is one of the four allowed types.');
    } else {
      setState({ ...state, alerts: [...state.alerts, detail] });
    }
  };

  const closeAlert = index => {
    const { alerts } = state;

    alerts.splice(index, 1);
    setState({ ...state, alerts });
  };

  useEffect(() => {
    window.addEventListener('alert', createAlert, false);

    return () => window.removeEventListener('alert', createAlert, false);
  }, []);

  useEffect(() => {
    if (!state.intervalId && state.alerts.length > 0) {
      const intervalId = setInterval(() => {
        const currentTime = new Date();

        state.alerts.forEach((alert, i) => {
          const seconds = currentTime - alert.time;

          if (seconds > 10000) {
            closeAlert(i);
          }
        });
      }, 1000);

      setState({ ...state, intervalId });
    } else if (state.intervalId && state.alerts.length === 0) {
      clearInterval(state.intervalId);
      setState({ ...state, intervalId: '' });
    }
  }, [state.intervalId, state.alerts]);

  return (
    <div className="alert-wrapper">
      {state.alerts.map((alert, i) => {
        const close = () => closeAlert(i);

        return (
          <div className={`alert ${alert.type}`} key={`${alert.type}-${alert.message}`}>
            <div className="alert-title">
              <span className="alert-type">{alert.type}</span>

              <div className="alert-close" onClick={close}>
                <CloseXSVG fill="#FFF" />
              </div>
            </div>
            <div className="alert-body">{alert.message}</div>
          </div>
        );
      })}
    </div>
  );
};

export default Alert;
