/* eslint-disable func-names */
const alert = type => {
  return function (message, data) {
    let newMessage = message;

    if (type === 'error') {
      newMessage += ' Try refreshing the page. If this does not work, please contact your recruiter for assistance.';
    }

    window.dispatchEvent(
      new CustomEvent('alert', {
        detail: { type, message: newMessage, data },
      }),
    );
  };
};

export default {
  error: alert('error'),
  info: alert('info'),
  success: alert('success'),
  warning: alert('warning'),
};
