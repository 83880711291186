import React from 'react';

export default function uploadPhoto() {
  return (
    <svg width="85px" height="85px" viewBox="0 0 85 85" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g id="Step-Two---Personal-Information" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Personal-Information---Incomplete" transform="translate(-446.000000, -296.000000)" stroke="#FF8300">
          <g id="Stacked-Group" transform="translate(427.000000, 224.000000)">
            <g id="Profile">
              <g transform="translate(20.000000, 73.000000)" id="Profile-Icon">
                <g>
                  <path
                    d="M19.2429854,75.8000776 C25.6801485,79.9797305 33.3599738,82.4073837 41.6068078,82.4073837 C49.7409042,82.4073837 57.3233884,80.0456509 63.7059393,75.9705063 L63.6396334,67.3707383 C63.5445037,55.0325702 53.4632348,45.0246571 41.1225448,45.0246571 L41.3504967,45.0246571 C29.0114675,45.0246571 19.0828388,55.0293384 19.1779935,67.3707383 L19.2429854,75.8000776 Z"
                    id="Combined-Shape"
                    fill="#FFF8EF"
                  />
                  <circle id="Oval-3" fill="#FFF8EF" cx="42.0340762" cy="31.2852192" r="13.4578188" />
                  <circle id="Oval-2" cx="41.5" cy="41.5" r="41.1209992" />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
