import { DateTime } from 'luxon';

// General date conversion function
const getDateObject = (date, fromFormat = 'MMddyyyy') => {
  let newDate;

  if (!date) {
    newDate = DateTime.now();
  } else if (typeof date === 'string') {
    const isoDate = DateTime.fromISO(date);
    const formatDate = DateTime.fromFormat(date, fromFormat);

    newDate = isoDate.isValid ? isoDate : formatDate;
  } else if (typeof date === 'number') {
    newDate = DateTime.fromMillis(date);
  } else {
    newDate = DateTime.isDateTime(date) ? date : DateTime.fromJSDate(date);
  }

  if (!newDate.isValid) {
    throw new Error('dateUtils.getDateObject(): Invalid date provided');
  }

  return newDate.toLocal();
};

// Get total number of days between dates
const getDiff = (dateProvided, fromDate, type = 'days', dateFormat, fromDateFormat) => {
  if (!dateProvided) {
    throw new Error('dateUtils.getDiff(): No date provided');
  }

  const date = getDateObject(dateProvided, dateFormat);
  const fromDateObj = getDateObject(fromDate, fromDateFormat);

  return Math.floor(fromDateObj.diff(date, type).toObject()[type]);
};

// Check if date is less than expected age
const compareAge = (dateProvided, age = 18, format) => getDiff(dateProvided, null, 'years', format) < age;

export default {
  getDateObject,
  getDiff,
  compareAge,
};
