import get from 'lodash.get';
import { candidateUtil } from '../utils';

const preMaskDate = date => {
  if (date) {
    const maskedDate = [date.slice(0, 2), date.slice(2, 4), date.slice(4, 8)].join('/');

    return maskedDate;
  }

  return date;
};

export const emptyAddress = {
  streetLine1: '',
  streetLine2: '',
  city: '',
  state: '',
  zip: '',
};

export function getProfileContext(candidate) {
  const { org } = candidateUtil.getWorkflow(candidate) || {};
  const defaultFieldText = org === 'field' ? '' : 'N/A';

  return {
    inputs: {
      phone: {
        path: 'personalInfo.contact.phone',
        value: get(candidate, 'personalInfo.contact.phone', ''),
      },
      text: {
        path: 'notifications.text',
        value: get(candidate, 'notifications.text', false),
      },
      address1: {
        path: 'personalInfo.contact.mailingAddress.address1',
        value: get(candidate, 'personalInfo.contact.mailingAddress.address1', ''),
      },
      address2: {
        path: 'personalInfo.contact.mailingAddress.address2',
        value: get(candidate, 'personalInfo.contact.mailingAddress.address2', ''),
      },
      city: {
        path: 'personalInfo.contact.mailingAddress.city',
        value: get(candidate, 'personalInfo.contact.mailingAddress.city', ''),
      },
      driversLicense: {
        path: 'personalInfo.PII.driversLicense',
        value: get(candidate, 'personalInfo.PII.driversLicense', defaultFieldText),
      },
      licenseState: {
        path: 'personalInfo.PII.licenseState',
        value: get(candidate, 'personalInfo.PII.licenseState', defaultFieldText),
      },
      state: {
        path: 'personalInfo.contact.mailingAddress.state',
        value: get(candidate, 'personalInfo.contact.mailingAddress.state', ''),
      },
      zip: {
        path: 'personalInfo.contact.mailingAddress.zip',
        value: get(candidate, 'personalInfo.contact.mailingAddress.zip', ''),
      },
      SSN: {
        path: 'personalInfo.PII.SSN',
        value: get(candidate, 'personalInfo.PII.SSN', ''),
      },
      DOB: {
        path: 'personalInfo.PII.DOB',
        value: preMaskDate(get(candidate, 'personalInfo.PII.DOB', '')),
      },
      gender: {
        path: 'personalInfo.PII.gender',
        value: get(candidate, 'personalInfo.PII.gender', ''),
      },
      race: {
        path: 'personalInfo.PII.race',
        value: get(candidate, 'personalInfo.PII.race', ''),
      },
      militaryStatus: {
        path: 'personalInfo.PII.militaryStatus',
        value: get(candidate, 'personalInfo.PII.militaryStatus', ''),
      },
      relationship: {
        path: 'personalInfo.contact.emergency.0.relationship',
        value: get(candidate, 'personalInfo.contact.emergency.0.relationship', ''),
      },
      ECFirstName: {
        path: 'personalInfo.contact.emergency.0.firstName',
        value: get(candidate, 'personalInfo.contact.emergency.0.firstName', ''),
      },
      ECLastName: {
        path: 'personalInfo.contact.emergency.0.lastName',
        value: get(candidate, 'personalInfo.contact.emergency.0.lastName', ''),
      },
      ECPhone: {
        path: 'personalInfo.contact.emergency.0.phone',
        value: get(candidate, 'personalInfo.contact.emergency.0.phone', ''),
      },
      ECEmail: {
        path: 'personalInfo.contact.emergency.0.email',
        value: get(candidate, 'personalInfo.contact.emergency.0.email', ''),
      },
      shirtPoloSize: {
        path: 'personalInfo.apparel.shirtPoloSize',
        value: get(candidate, 'personalInfo.apparel.shirtPoloSize', ''),
      },
      jacketSize: {
        path: 'personalInfo.apparel.jacketSize',
        value: get(candidate, 'personalInfo.apparel.jacketSize', ''),
      },
      athleticPantSize: {
        path: 'personalInfo.apparel.athleticPantSize',
        value: get(candidate, 'personalInfo.apparel.athleticPantSize', ''),
      },
      pantSize: {
        path: 'personalInfo.apparel.pantSize',
        value: get(candidate, 'personalInfo.apparel.pantSize', ''),
      },
      shoeSize: {
        path: 'personalInfo.apparel.shoeSize',
        value: get(candidate, 'personalInfo.apparel.shoeSize', ''),
      },
      hatSize: {
        path: 'personalInfo.apparel.hatSize',
        value: get(candidate, 'personalInfo.apparel.hatSize', ''),
      },
    },
    photo: {
      file: null,
      url: get(candidate, 'photo.url', ''),
    },
    verified: false,
    addressError: '',
    modalOpen: false,
    verifiedAddress: emptyAddress,
    userAddress: emptyAddress,
    action: null,
  };
}
