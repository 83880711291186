import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Form extends Component {
  constructor(props) {
    super(props);
    this.keys = {};
    this.update = this.update.bind(this);
    this.reset = this.reset.bind(this);
    this.validateForm = this.validateForm.bind(this);
  }

  getChildContext() {
    return {
      validateForm: this.validateForm,
      form: this.props.name,
    };
  }

  /* eslint-disable camelcase */
  UNSAFE_componentWillMount() {
    /* eslint-enable camelcase */
    const { state } = this.props;
    const mountState = { ...state };

    for (const element in mountState) {
      if (mountState.hasOwnProperty(element)) {
        this.keys[element] = {
          checked: false,
          validated: false,
        };
      }
    }
  }

  reset() {
    for (const element in this.keys) {
      if (this.keys.hasOwnProperty(element)) {
        this.keys[element].checked = false;
      }
    }
  }

  update(element, value) {
    this.keys[element].validated = value;
    this.keys[element].checked = true;
  }

  verifyCompletion() {
    for (const element in this.keys) {
      if (!this.keys[element].checked) {
        return false;
      }
    }

    return true;
  }

  verifyValidation() {
    for (const element in this.keys) {
      if (!this.keys[element].validated) {
        return {
          validated: false,
          validationError: `Validation failed at ${element}`,
          element,
        };
      }
    }

    return {
      validated: true,
      validationError: null,
      component: null,
    };
  }

  validateForm(checkSelf) {
    const { element, validated } = checkSelf();

    this.update(element, validated);

    const isComplete = this.verifyCompletion();

    if (isComplete) {
      const result = this.verifyValidation();

      this.props.onSubmit(result);
    }

    return null;
  }

  render() {
    return (
      <form onSubmit={this.reset} className={this.props.className} id={this.props.name}>
        {this.props.children}
      </form>
    );
  }
}

Form.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  children: PropTypes.array,
  state: PropTypes.object,
};

Form.childContextTypes = {
  validateForm: PropTypes.func,
  form: PropTypes.string.isRequired,
};

export default Form;
