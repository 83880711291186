import React, { useContext, useEffect, useState } from 'react';
import { AppStateContext } from '../../../context/StateContext';
import { docusignService } from '../../../services';
import { candidateUtil } from '../../../utils';
import LoadingAnimation from '../../../components/loadingAnimation/LoadingAnimation';

const Docusign = props => {
  const { candidate, currentTask, updateCandidate } = useContext(AppStateContext);
  const { _id, taskTitle } = currentTask;
  const [localState, setLocal] = useState({
    taskUri: null,
    envelopeId: null,
  });
  const { envelopeId, taskUri } = localState;

  // Get the envelopeId and taskUri
  useEffect(() => {
    const updateDOM = async () => {
      try {
        const envelopeId = await docusignService.getEnvelope(candidate._id, currentTask._id);
        const taskUri = await docusignService.getSignedDocusignUrl(candidate._id, envelopeId);

        setLocal({ ...localState, envelopeId, taskUri });
      } catch (error) {
        console.log('error:', error);

        await docusignService.voidEnvelope(candidate._id, currentTask._id);
        updateDOM();
      }
    };

    updateDOM(props);
  }, [candidate._id, _id]);

  // Check to see if the document has been completed
  const handleSubmit = async event => {
    event.preventDefault();

    try {
      const { envelopes = [] } = await docusignService.getEnvelopeStatus(envelopeId);

      // If the document is completed, update the candidate
      if (envelopes[0].status === 'completed') {
        const auditMessage = `${taskTitle} Completed`;
        let temporaryCandidate = candidate;

        temporaryCandidate = candidateUtil.updateTaskUri(temporaryCandidate, _id, envelopeId);
        temporaryCandidate = candidateUtil.dateStampCandidate(temporaryCandidate, _id, 'complete');
        updateCandidate(temporaryCandidate, auditMessage, 'docusign');
        setLocal({ ...localState });
      }
    } catch (error) {
      console.error(error);
    }
  };

  // Decide what to render bassed on if there is a task URI or not
  let docusign = <LoadingAnimation />;

  if (taskUri) {
    docusign = <iframe title="docusign" src={taskUri} onLoad={handleSubmit} />;
  }

  return <div className="onboarding-wrapper">{docusign}</div>;
};

export default Docusign;
