import React, { Component } from 'react';
import PropTypes from 'prop-types';
import inputMask from 'inputmask';
import './formComponents.scss';

class Input extends Component {
  constructor(props) {
    super(props);
    this.state = {
      message: '',
    };
    this.checkSelf = this.checkSelf.bind(this);
  }

  componentDidMount() {
    if (this.context.form) {
      const { id } = this.props;
      const form = document.forms[this.context.form];
      const element = document.querySelector(`#${id}`);

      if (element.getAttribute('data-inputmask')) {
        inputMask().mask(element);
      }

      if (form.addEventListener) {
        form.addEventListener(
          'submit',
          event => {
            event.preventDefault();
            this.context.validateForm(this.checkSelf);
          },
          false,
        );
      }
    }
  }

  checkSelf() {
    if (this.context.form) {
      const { value } = this.props;
      const { validated, message } = this.props.validate(value, this.props.optional);

      if (validated) {
        this.setState({
          message: '',
        });
      } else if (message) {
        this.setState({
          message,
        });
      }

      return {
        element: this.props.id,
        validated,
      };
    }

    return null;
  }

  render() {
    return (
      <div className="formComponent" onBlur={this.checkSelf}>
        <label htmlFor={this.props.id}>{this.props.label}</label>
        <input
          placeholder={this.props.placeholder || ''}
          type="text"
          maxLength={this.props.maxLength || ''}
          style={{ width: this.props.width || '200px' }}
          id={this.props.id}
          data-inputmask={this.props.inputMask || ''}
          value={this.props.value}
          onChange={this.props.onChange}
        />
        <div className="invalid width200">{this.state.message}</div>
      </div>
    );
  }
}

Input.propTypes = {
  label: PropTypes.string,
  width: PropTypes.string,
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  validate: PropTypes.func,
};

Input.contextTypes = {
  validateForm: PropTypes.func,
  form: PropTypes.string,
};

export default Input;
