const RED = '#f85359';
const LIGHT_RED = '#ffe0e0';
const GREEN = '#00b085';
const YELLOW = '#f4b322';
const LIGHT_YELLOW = '#ffd35c';
const BLUE = '#001a3c';
const LIGHT_BLUE = '#0073e0';

const WHITE = '#fff';
const BLACK = '#000';
const DARK_GRAY = '#363739';
const MEDIUM_GRAY = '#4a4a4a';
const LIGHT_GRAY = '#959497';
const BORDER_GRAY = '#bcbec0';
const LIGHTER_GRAY = '#e5e5e5';
const OFF_WHITE = '#F7F8F8';

const OUTER_PADDING = '16px';
const INNER_PADDING = '16px';
const EDGE_PADDING = '16px';
const ELEMENT_PADDING = '8px';
const BORDER_RADIUS = '4px';
const LABEL_SIZE = '12px';
const FONT_SIZE = '16px';

const FONT = 'Untitled-Sans-Regular, Arial';

module.exports = {
  RED,
  LIGHT_RED,
  GREEN,
  YELLOW,
  LIGHT_YELLOW,
  BLUE,
  LIGHT_BLUE,
  WHITE,
  BLACK,
  DARK_GRAY,
  MEDIUM_GRAY,
  LIGHT_GRAY,
  BORDER_GRAY,
  LIGHTER_GRAY,
  OFF_WHITE,
  OUTER_PADDING,
  INNER_PADDING,
  EDGE_PADDING,
  ELEMENT_PADDING,
  BORDER_RADIUS,
  LABEL_SIZE,
  FONT_SIZE,
  FONT,
};
