import React from 'react';

const Policies = ({ color, height, width }) => {
  return (
    <svg height={height || '24px'} width={width || '24px'} viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <title>menu_book-24px</title>
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Artboard" transform="translate(-200.000000, -498.000000)">
          <g id="Group-3-Copy-3" transform="translate(170.000000, 0.000000)">
            <g id="Group-4-Copy" transform="translate(19.000000, 498.000000)">
              <g id="menu_book-24px" transform="translate(11.000000, 0.000000)">
                <rect id="Rectangle" x="0" y="0" width="24" height="24"></rect>
                <g id="Group" transform="translate(1.000000, 4.000000)" fill={color || '#fff'} fillRule="nonzero">
                  <path
                    d="M16.5,0.5 C17.67,0.5 18.89,0.65 20,1 C20.75,1.25 21.4,1.55 22,2 L22,2 L22,16.6 C22,16.85 21.75,17.1 21.5,17.1 C21.4,17.1 21.35,17.1 21.25,17.05 C19.85,16.3 18.15,16 16.5,16 C14.8,16 12.35,16.65 11,17.5 C9.55,16.4 7.45,16 5.5,16 C4.05,16 2.1,16.45 0.75,17.1 C0.65,17.1 0.6,17.15 0.5,17.15 C0.25,17.15 0,16.9 0,16.65 L0,16.65 L0,2 C1.45,0.9 3.55,0.5 5.5,0.5 C7.45,0.5 9.55,0.9 11,2 C12.45,0.9 14.55,0.5 16.5,0.5 Z M16.5,2.5 C14.8,2.5 12.35,3.15 11,4 L11,4 L11,15.5 C12.35,14.65 14.8,14 16.5,14 C17.7,14 18.9,14.15 20,14.5 L20,14.5 L20,3 C18.9,2.65 17.7,2.5 16.5,2.5 Z M16.5,10.33 C17.36,10.33 18.21,10.41 19,10.57 L19,10.57 L19,12.09 C18.23,11.92 17.38,11.83 16.5,11.83 C14.7,11.83 13.13,12.18 12,12.82 L12,12.82 L12,11.16 C13.26,10.62 14.8,10.33 16.5,10.33 Z M16.5,7.66 C17.36,7.66 18.21,7.75 19,7.9 L19,7.9 L19,9.42 C18.23,9.25 17.38,9.16 16.5,9.16 C14.7,9.16 13.13,9.51 12,10.15 L12,10.15 L12,8.49 C13.26,7.96 14.8,7.66 16.5,7.66 Z M16.5,5 C17.36,5 18.21,5.09 19,5.24 L19,5.24 L19,6.76 C18.23,6.59 17.38,6.5 16.5,6.5 C14.7,6.5 13.13,6.85 12,7.49 L12,7.49 L12,5.83 C13.26,5.29 14.8,5 16.5,5 Z"
                    id="Combined-Shape"
                  ></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default Policies;
