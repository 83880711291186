import React from 'react';
import PropTypes from 'prop-types';
import Modal from '../Modal';
import './eligibilityModal.scss';

const eligibilityModal = ({ active, eligible }) => {
  let open = false;

  if (typeof eligible === 'boolean') {
    open = !eligible;
  }

  return (
    <div className="eligibility-modal-wrapper">
      <Modal title="Previous History Detected" open={open}>
        {active ? (
          <p>
            It seems you're already a Sunrun Employee and active in Workday. Please contact your manager and request a transfer. We will not need you to
            re-onboard at this time. Thanks!
          </p>
        ) : (
          <p>It seems you've already been a Sunrun Employee. Please contact your onboarding consultant as the way you onboard may need to change. Thanks!</p>
        )}
      </Modal>
    </div>
  );
};

eligibilityModal.propTypes = {
  active: PropTypes.bool,
  eligible: PropTypes.bool,
};

export default eligibilityModal;
