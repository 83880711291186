import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { AppStateContext } from '../../../context/StateContext';
import { candidateUtil } from '../../../utils';
import BodyHeader from '../../../components/bodyHeader/BodyHeader';

const Complete = props => {
  const { candidate } = useContext(AppStateContext);
  const org = candidateUtil.determineOrg(candidate);
  const [hasOpenedNewWindow, setHasOpenedNewWindow] = useState(false);

  useEffect(() => {
    if (props.uri && !hasOpenedNewWindow) {
      const newWindow = window.open(props.uri, '_blank', 'noopener,noreferrer');
      if (newWindow) newWindow.opener = null;
      setHasOpenedNewWindow(true);
    }
  }, [props.uri]);

  const almostFinished = org => {
    const sales = `Now it’s time to complete Section 1 of your I-9 AND meet with a friend or neighbor and provide them your government-issued ID(s) to complete Section 2 of your I-9.
    
Here’s what you need to do!`;
    const notSales = `Now that you have finished signing your new hire paperwork and completed pre-employment screenings, we want to tell you about your next steps:`;
    return (
      <>
        <h1>You are almost finished!</h1>
        <p>{candidateUtil.Orginazations.SALES === org ? sales : notSales}</p>
      </>
    );
  };
  const getListItem = (header, mainText, headerLink, mainTextlink) => (
    <>
      <h3>
        {header}
        {headerLink && (
          <a href={headerLink.uri} target="_blank" rel="noopener noreferrer">
            {headerLink.text}
          </a>
        )}
      </h3>
      <p>
        {mainText}
        {mainTextlink && (
          <a href={mainTextlink.uri} target="_blank" rel="noopener noreferrer">
            {mainTextlink.text}
          </a>
        )}
      </p>
    </>
  );

  const step1 = (org, uri) => {
    const complete = candidateUtil.Orginazations.SALES === org ? 'To complete' : 'Complete';
    const header = `1. ${complete} Section 1 of the I-9, `;
    const mainText = 'A new window will open. Please follow the instructions to complete Section 1 of your I-9 and click “Next.”';
    return getListItem(header, mainText, { uri, text: 'click here' });
  };
  const step2 = () => {
    const mainText = `who will receive instructions via email on how to upload and verify your identification online on your behalf.
    
*Note: You will be able to indicate if you do NOT yet know the contact info for your friend.`;
    const header = '2. Next, provide the name of your friend or neighbor';
    return getListItem(header, mainText);
  };
  const step3 = () => {
    const mainText = `look for an email entitled IMPORTANT: Form I-9 Follow Up Actions. Enter the name and email address of a trusted friend or neighbor and follow the instructions.`;
    const header = `3. If you did not provide the name of your friend or neighbor in the step above`;
    return getListItem(header, mainText);
  };

  const step4 = org => {
    const mainTextLink = {
      uri: 'https://s3-us-west-2.amazonaws.com/com.vivintsolar.sites/hotrod/List_of_Acceptable_Documents.pdf',
      text: 'See list of acceptable IDs',
    };
    const header = `4. Grab your government ID(s)${
      candidateUtil.Orginazations.CORPORATE === org ? '.' : ', once you have provided your Friends or Neighbor info.'
    }`;
    return getListItem(header, '', null, mainTextLink);
  };
  const step5 = () => {
    const mainText = `Validate with your friend or neighbor they have received an email entitled Form I-9: Section 2 Ready for Processing. They will use this email to complete your section 2.
    
*If they have not received this email please contact your Onboarding Consultant.
    
Once they receive this email, please meet with them in-person and provide them your government issued ID(s) to complete section 2 of your I-9 within the next 72 hours.`;
    const header = `5. Meet with your friend or neighbor in-person to provide them your section 2 I-9 original ID(s).`;
    return getListItem(header, mainText);
  };

  const step6 = org => {
    const header = candidateUtil.Orginazations.SALES === org ? `6. Watch for your email and password to login to the Sales and HR systems.` : '';
    const mainText =
      candidateUtil.Orginazations.SALES === org
        ? `Once these steps occur, you will receive a welcome email with information on how to access our system and complete training.`
        : '';
    return mainText && header ? getListItem(header, mainText) : '';
  };
  const footer = org => {
    const print = `We recommend that you print or save this screen for future reference.`;
    const linkedIn = `In preparation for your new adventure with Sunrun, please be sure to update your LinkedIn profile!  `;

    const welcome = `${candidateUtil.Orginazations.CORPORATE === org ? linkedIn : ''}We look forward to having you join our team!`;
    return (
      <>
        <br />
        <br />
        {candidateUtil.Orginazations.CORPORATE !== org && <p>{print}</p>}
        <br />
        {candidateUtil.Orginazations.SALES !== org && <p>{welcome}</p>}
      </>
    );
  };

  const i9Task = candidateUtil.getCurrentTask(candidate);

  return (
    <div className="complete-task-wrapper">
      <BodyHeader />

      <div className="complete-task">
        {/* ======= System Error ======= */}

        {props.showError && (
          <div className="complete-task__systemerror">
            System down. Please contact your {org === 'corporate' || org === 'field' ? `recruiter` : 'onboarding specialist at (385) 352-3614'} who will help
            provide instructions on how to complete your I-9.
          </div>
        )}

        {/* ======= Almost Finished message ======= */}
        {almostFinished(org)}
        {/* ======= Step 1 ======= */}
        {step1(org, props.uri || i9Task.data[0].URI)}
        {/* ======= Step 2 ======= */}
        {step2()}
        {/* ======= Step 3 ======= */}
        {step3()}
        {/* ======= Step 4 ======= */}
        {step4(org)}
        {/* ======= Step 5 ======= */}
        {step5()}
        {/* ======= Step 6 ======= */}
        {step6(org)}
        {/* ======= Print Screen ======= */}
        {footer(org)}
      </div>
    </div>
  );
};

Complete.propTypes = {
  candidate: PropTypes.object,
  showError: PropTypes.bool,
  uri: PropTypes.string,
};

export default Complete;
