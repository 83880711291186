import React from 'react';
import PropTypes from 'prop-types';
import svgs from '../svgs';
import { candidateUtil } from '../../utils';

const ProgressNav = props => {
  const { candidate } = props;
  const { tasks } = candidateUtil.getWorkflow(candidate);
  const sections = {};

  // Sort tasks into sections
  tasks.forEach(task => {
    const key = task.sectionTitle || task.sectionKey;
    let sectionNumber = Object.keys(sections).length;

    if (!sections[`${sectionNumber}${key}`]) {
      sectionNumber += 1;
      sections[`${sectionNumber}${key}`] = [];
    }

    sections[`${sectionNumber}${key}`].push({
      start: task.analytics.start,
      complete: candidateUtil.checkTaskCompletion(task),
      key: task.sectionKey,
      title: candidateUtil.cleanString(key),
    });
  });

  // Build elements bassed of sorted sections
  const progressNavBody = [];

  Object.keys(sections).forEach((key, i, array) => {
    const section = sections[key];
    const done = section.reduce((sum, { start }) => sum + (start ? 1 : 0), 0);
    const firstTask = section[0];
    const lastTask = section[section.length - 1];
    const started = firstTask.start;
    const color = started ? '#FFF' : '#959497';
    const svgSettings = { color, height: '30px', width: '30px' };

    progressNavBody.push(
      <section key={i} style={{ color }}>
        {svgs[lastTask.complete ? 'complete' : firstTask.key](svgSettings)}
        <p>{firstTask.title}</p>
        <p>
          {done} / {section.length}
        </p>
      </section>,
    );

    if (i + 1 !== array.length) {
      progressNavBody.push(<div key={`${i}line`} className="line-in-middle" />);
    }
  });

  return (
    <div className="progressNav-wrapper">
      <div className="progressNav-ends progressNav-header">Start</div>
      <div className="progressNav-body">{progressNavBody}</div>
      <div className="progressNav-ends progressNav-footer">Finish</div>
    </div>
  );
};

ProgressNav.propTypes = {
  candidateTaskData: PropTypes.object,
  sections: PropTypes.array,
};

export default ProgressNav;
