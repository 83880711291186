import React from 'react';

const BackgroundCheck = ({ color, height, width }) => {
  return (
    <svg height={height || '24px'} width={width || '24px'} viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <title>contact_page-24px</title>
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Artboard" transform="translate(-30.000000, -276.000000)">
          <g id="Group-3" transform="translate(0.000000, 52.000000)">
            <g id="contact_page-24px" transform="translate(30.000000, 224.000000)">
              <rect id="Rectangle" x="0" y="0" width="24" height="24"></rect>
              <path
                d="M14,2 L6,2 C4.9,2 4,2.9 4,4 L4,20 C4,21.1 4.9,22 6,22 L18,22 C19.1,22 20,21.1 20,20 L20,8 L14,2 Z M12,10 C13.1,10 14,10.9 14,12 C14,13.1 13.1,14 12,14 C10.9,14 10,13.1 10,12 C10,10.9 10.9,10 12,10 Z M16,18 L8,18 L8,17.43 C8,16.62 8.48,15.9 9.22,15.58 C10.07,15.21 11.01,15 12,15 C12.99,15 13.93,15.21 14.78,15.58 C15.52,15.9 16,16.62 16,17.43 L16,18 Z"
                id="Shape"
                fill={color || '#fff'}
                fillRule="nonzero"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default BackgroundCheck;
