import { alertUtil, candidateUtil } from '../utils';
import igniteAPI from './axios';

const login = async (id, email) => {
  try {
    return await igniteAPI.post(`/candidate/${id}/login`, { email, redirectUrl: null });
  } catch (error) {
    alertUtil.error('This email does not match the one we have on file for you.', error);
    throw error;
  }
};

const getCandidate = async id => {
  try {
    return await igniteAPI.get(`/candidate/${id}`);
  } catch (error) {
    alertUtil.error('There was an error retriving your data. Try refreshing the page, or contacting your manager', error.message);
    throw error;
  }
};

const updateCandidate = async (candidate, message = '', type = '') => {
  const workflowData = candidateUtil.getWorkflow(candidate);
  const body = { candidate };

  workflowData.lastCandidateInteraction = new Date();

  if (message) {
    body.additional = { message };
  }

  try {
    return await igniteAPI.put(`/candidate/${candidate._id}${type || message ? `?type=${type || 'task'}` : ''}`, body);
  } catch (error) {
    alertUtil.error('There was an error updating your data.', error.message);
    throw error;
  }
};

const verifyAddress = async candidate => {
  const address = {
    streetLine1: candidate.address1.value,
    streetLine2: candidate.address2.value,
    city: candidate.city.value,
    state: candidate.state.value,
    zip: candidate.zip.value,
    geoloc: false,
  };

  // Make call to verify zip
  try {
    return await igniteAPI.post(`/candidate/verifyaddress`, address);
  } catch (error) {
    console.error(error);

    return {
      verified: false,
      address: {
        streetLine1: '',
        streetLine2: '',
        city: '',
        state: '',
        zip: '',
        country: '',
      },
    };
  }
};

const addNote = async (candidate, message, user = {}) => {
  const note = {
    user: {
      id: user.id || '00000',
      name: user.name || 'Automated Message',
    },
    message,
  };

  try {
    return await igniteAPI.post(`/candidate/${candidate._id}/note`, note);
  } catch (error) {
    alertUtil.error('There was an error adding a note to your candidate profile.', error.message);
    throw error;
  }
};

const readFileAsync = async (file = {}) => {
  return new Promise((resolve, reject) => {
    if (file.type) {
      const reader = new FileReader();

      reader.readAsDataURL(file);
      reader.addEventListener('error', reject);
      reader.addEventListener('load', () => resolve(reader.result));
    } else {
      reject(new Error('No file type found'));
    }
  });
};

const uploadPhoto = async (id, file = {}) => {
  const fileResult = await readFileAsync(file);
  const [, imageType] = file.type.split('/');
  const formData = {
    title: `${id}.${imageType}`,
    type: file.type,
    file: fileResult,
  };

  try {
    return await igniteAPI.post(`/candidate/photo/${id}`, formData);
  } catch (error) {
    alertUtil.error('There was an error uploading your profile photo.', error.message);
    throw error;
  }
};

const getPhoto = async candidate => {
  try {
    return await igniteAPI.get(`/candidate/photo/${candidate._id}`);
  } catch (error) {
    alertUtil.warning('There was an error retriving a previous profile photo.', error.message);
    throw error;
  }
};

export default {
  getCandidate,
  login,
  updateCandidate,
  verifyAddress,
  addNote,
  uploadPhoto,
  getPhoto,
};
