import React, { Component } from 'react';
import LoadingAnimation from '../components/loadingAnimation/LoadingAnimation';

class StatusPage extends Component {
  render() {
    return (
      <div className="status-page-wrapper">
        <LoadingAnimation />
      </div>
    );
  }
}

export default StatusPage;
