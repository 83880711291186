import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import { AppStateContext } from '../../../context/StateContext';
import { getProfileContext, emptyAddress } from '../../../context/profileContext';
import { candidateService } from '../../../services';
import { addressUtil, candidateUtil, workdayMap } from '../../../utils';
import AddressVerification from '../../../components/modal/addressVerification/AddressVerification';
import TaskFooter from '../../../components/taskFooter/TaskFooter';
import { Form, Input, Select, Checkbox, validate } from '../../../components/reactForms';

const Personal = () => {
  const { candidate, currentTask, updateCandidate } = useContext(AppStateContext);
  const { _id, taskTitle } = currentTask;
  const [localState, setLocal] = useState(getProfileContext(candidate));
  const { inputs, verified, addressError, modalOpen, userAddress, verifiedAddress, photo, action } = localState;
  const { org } = candidateUtil.getWorkflow(candidate) || {};
  const field = org && org === 'field';

  // Update the photo url with the one stored in aws
  useEffect(() => {
    const getPhotoUrl = async () => {
      try {
        const photoUrl = await candidateService.getPhoto(candidate);

        if (photoUrl) {
          localState.photo.url = photoUrl;
          setLocal(localState);
        }
      } catch (error) {
        console.log('error:', error);
      }
    };

    if (photo.url) {
      getPhotoUrl();
    }
  }, [photo.url]);

  useEffect(() => {
    if (action === 'runUpdateCandidate') {
      runUpdateCandidate();
    } else if (action === 'openModal') {
      openModal();
    }
  }, [action]);

  const closeModal = () => {
    setLocal({
      ...localState,
      modalOpen: false,
      action: '',
    });
  };

  const openModal = () => {
    setLocal({
      ...localState,
      modalOpen: true,
    });
  };

  const handleInputChange = event => {
    if (event.target.type !== 'checkbox') {
      event.preventDefault();
    }

    const key = event.target.id;
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;

    localState.inputs[key].value = value;
    setLocal({ ...localState });
  };

  const handleSubmit = validationResult => {
    const { validated, validationError, element } = validationResult;

    // Check to see if inputs are valid and if address verification is required
    if (validated) {
      return verifyUserAddress(element);
    }

    window.location.href = `#${element}`;

    return console.warn(validationError);
  };

  const loadImage = event => {
    const file = event.target.files[0];

    setLocal({
      ...localState,
      photo: {
        file: file || null,
        url: file ? URL.createObjectURL(file) : null,
      },
    });
  };

  const modalResponse = (selectedAddress, verified) => {
    const stateUpdate = localState;

    stateUpdate.inputs.address1.value = selectedAddress.streetLine1;
    stateUpdate.inputs.address2.value = selectedAddress.streetLine2;
    stateUpdate.inputs.city.value = selectedAddress.city;
    stateUpdate.inputs.state.value = selectedAddress.state;
    stateUpdate.inputs.zip.value = selectedAddress.zip;
    stateUpdate.verified = verified;
    stateUpdate.modalOpen = false;
    stateUpdate.action = '';

    setLocal(stateUpdate);

    runUpdateCandidate(stateUpdate);
  };

  const runUpdateCandidate = async updatedState => {
    const { inputs, verified, photo } = updatedState || localState;
    const state = inputs.state.value;
    const candidatePhoto = get(candidate, 'photo.url');

    inputs.verified = {
      path: 'personalInfo.contact.mailingAddress.verified',
      value: verified,
    };

    if (!/USA/g.test(state)) {
      const stateResult = workdayMap.state.find(stateObject => {
        return stateObject.abbreviation === inputs.state.value;
      });

      inputs.state.value = stateResult ? stateResult.value : state;
    }

    try {
      let temporaryCandidate = { ...candidate };
      let photoError = null;

      if (photo.file) {
        try {
          await candidateService.uploadPhoto(temporaryCandidate._id, photo.file);
        } catch (error) {
          photoError = error;
        }
      }

      if ((!candidatePhoto && !photo.url) || photoError) {
        const message =
          !candidatePhoto && !photo.url
            ? 'A photo was not uploaded for this candidate during the personal info task.'
            : `
				There was an error uploading the photo for this candidate during the personal info task.

				For Developer Reference:
				${photoError}
				`;

        temporaryCandidate.admin.notes.push({
          message,
          user: { name: 'Automated Message', id: 0 },
        });
      }

      temporaryCandidate = candidateUtil.applyFormInputs(temporaryCandidate, inputs);
      temporaryCandidate = candidateUtil.dateStampCandidate(temporaryCandidate, _id, 'complete');

      await updateCandidate(temporaryCandidate, `${taskTitle} Completed`);
    } catch (error) {
      console.error(error);
    }
  };

  const verifyUserAddress = async () => {
    const { inputs } = localState;
    const userAddress = {
      streetLine1: inputs.address1.value,
      streetLine2: inputs.address2.value,
      city: inputs.city.value,
      state: inputs.state.value,
      zip: inputs.zip.value,
      country: 'US',
    };

    try {
      const { verified, address, suggestion, message } = await candidateService.verifyAddress(inputs);
      const verifiedAddressString = addressUtil.buildFullAddress(address || suggestion);
      const userAddressString = addressUtil.buildFullAddress(userAddress);
      const matchingAddress = verifiedAddressString === userAddressString;
      let action = 'openModal';

      if (verifiedAddressString && matchingAddress && verified) {
        action = 'runUpdateCandidate';
      }

      setLocal({
        ...localState,
        addressError: message || '',
        verified,
        verifiedAddress: address || suggestion,
        userAddress,
        action,
      });
    } catch (error) {
      console.error(error);
      setLocal({
        ...localState,
        addressError: 'Issues validating address',
        verified: false,
        verifiedAddress: emptyAddress,
        userAddress,
        action: 'openModal',
      });
    }
  };

  return (
    <div>
      <Form name="Personal" className="Form-wrapper" onSubmit={handleSubmit} state={inputs}>
        <section>
          <h1>Profile Image</h1>
          <br />A company photo is required for your badge and our employee system. If you are not able to upload a photo at this time please have an Office
          Admin in your local office take your photo.
          <div className="file">
            <div className="file-upload">
              <input type="file" name="photo" id="photo" accept="image/jpeg, image/png" onChange={loadImage} />
              <label className="file-select-button" htmlFor="photo">
                Choose a file...
              </label>
              {photo.url && (
                <div className="image">
                  <img src={photo.url} alt="Profile display" />
                </div>
              )}
              {!photo.url && <div className="image">Upload a profile photo</div>}
            </div>

            <div className="file-instructions">
              <h3>Requirements</h3>
              <div>1. Photo must be of you</div>
              <div>2. Photo must not include sunglasses, hats, or headgear</div>
              <div>3. Must be a color photo without filters and with normal contrast and lighting</div>
              <div>4. Photos must present full face with eyes open from top of hair to shoulders and be front facing</div>
              <div>5. Photo must be in-focus</div>
              <div>6. Photos should include prescription glasses, a hearing device, wig or similar articles, that are normally worn for medical reasons.</div>
            </div>
          </div>
        </section>

        <section>
          <h1>Contact</h1>
          <div>
            <Input
              label="Phone Number"
              id="phone"
              onChange={handleInputChange}
              value={inputs.phone.value}
              validate={validate.phone}
              placeholder="(___) ___-____"
              inputMask="'mask': '(999) 999-9999'"
            />
            <div>
              <Input label="Address Line 1" id="address1" onChange={handleInputChange} value={inputs.address1.value} validate={validate.text} width="500px" />
              <Input
                label="Address Line 2"
                id="address2"
                onChange={handleInputChange}
                value={inputs.address2.value}
                validate={validate.text}
                optional="true"
                width="500px"
              />
            </div>
            <div className="flex wrap">
              <Input label="City" id="city" onChange={handleInputChange} value={inputs.city.value} validate={validate.text} />
              <Select
                label="State"
                id="state"
                onChange={handleInputChange}
                value={inputs.state.value}
                validate={validate.select}
                options={workdayMap.state}
                width="130px"
              />
              <Input label="Zip Code" id="zip" validate={validate.zipCode} onChange={handleInputChange} value={inputs.zip.value} width="125px" maxLength="5" />
            </div>
            {!verified && <div className="invalid">{addressError}</div>}
            <div>
              <Checkbox
                label="You will receive quick reminders and helpful updates via email as you onboard. Would you like to receive text messages as well?"
                id="text"
                onChange={handleInputChange}
                value={inputs.text.value}
              />
            </div>
          </div>
        </section>
        <section>
          <h1>Additional Information</h1>
          <div>
            <div className="flex wrap">
              <Input
                label="US Social Security Number"
                id="SSN"
                onChange={handleInputChange}
                value={inputs.SSN.value}
                validate={validate.ssn}
                placeholder="___-__-____"
                inputMask="'mask': '999-99-9999'"
              />
              <Input
                label="Date of Birth"
                id="DOB"
                onChange={handleInputChange}
                value={inputs.DOB.value}
                validate={validate.dob}
                placeholder="mm/dd/yyyy"
                inputMask="'alias': 'datetime', 'inputFormat': 'mm/dd/yyyy'"
              />
              <Select
                label="Gender"
                id="gender"
                onChange={handleInputChange}
                value={inputs.gender.value}
                validate={validate.select}
                options={workdayMap.gender}
                width="125px"
              />
            </div>
            <div className="flex wrap">
              <Select label="Race" id="race" onChange={handleInputChange} value={inputs.race.value} validate={validate.select} options={workdayMap.race} />
              <Select
                label="Military Status"
                id="militaryStatus"
                onChange={handleInputChange}
                value={inputs.militaryStatus.value}
                validate={validate.select}
                options={workdayMap.militaryStatus}
                optional
              />
            </div>
            <div className="flex wrap" style={{ display: field ? '' : 'none' }}>
              <Input
                label="Drivers License Number"
                id="driversLicense"
                onChange={handleInputChange}
                validate={validate.text}
                value={inputs.driversLicense.value}
                optional={!field}
              />
              <Select
                label="License State"
                id="licenseState"
                onChange={handleInputChange}
                value={inputs.licenseState.value}
                validate={validate.select}
                options={workdayMap.state}
                optional={!field}
              />
            </div>
          </div>
        </section>
        <section>
          <h1>Emergency Contact</h1>
          <div>
            <div>
              <Select
                label="Relationship"
                id="relationship"
                onChange={handleInputChange}
                value={inputs.relationship.value}
                validate={validate.select}
                options={workdayMap.relationship}
              />
            </div>
            <div className="flex wrap">
              <Input label="First Name" id="ECFirstName" onChange={handleInputChange} value={inputs.ECFirstName.value} validate={validate.legalName} />
              <Input label="Last Name" id="ECLastName" onChange={handleInputChange} value={inputs.ECLastName.value} validate={validate.legalName} />
            </div>
            <div className="flex wrap">
              <Input
                label="Phone Number"
                id="ECPhone"
                onChange={handleInputChange}
                value={inputs.ECPhone.value}
                validate={validate.phone}
                placeholder="(___) ___-____"
                inputMask="'mask': '(999) 999-9999'"
              />
              <Input label="Email Address" id="ECEmail" onChange={handleInputChange} value={inputs.ECEmail.value} validate={validate.email} />
            </div>
          </div>
        </section>
        <section>
          <h1>Apparel</h1>
          <br />
          Please provide your apparel sizes in the situation that you are provided a uniform or swag.
          <div className="flex wrap">
            <Select
              label="Shirt/Polo Size"
              id="shirtPoloSize"
              onChange={handleInputChange}
              value={inputs.shirtPoloSize.value}
              validate={validate.select}
              options={workdayMap.shirtPoloSize}
            />
            <Select
              label="Jacket Size"
              id="jacketSize"
              onChange={handleInputChange}
              value={inputs.jacketSize.value}
              validate={validate.select}
              options={workdayMap.jacketSize}
            />
            <Select
              label="Athletic Pants Size"
              id="athleticPantSize"
              onChange={handleInputChange}
              value={inputs.athleticPantSize.value}
              validate={validate.select}
              options={workdayMap.athleticPantSize}
            />
          </div>
          <div className="flex wrap">
            <Select
              label="Dress Pants Size"
              id="pantSize"
              onChange={handleInputChange}
              value={inputs.pantSize.value}
              validate={validate.select}
              options={workdayMap.pantSize}
            />
            <Select
              label="Shoe Size"
              id="shoeSize"
              onChange={handleInputChange}
              value={inputs.shoeSize.value}
              validate={validate.select}
              options={workdayMap.shoeSize}
            />
            <Select
              label="Hat Size"
              id="hatSize"
              onChange={handleInputChange}
              value={inputs.hatSize.value}
              validate={validate.select}
              optional="true"
              options={workdayMap.hatSize}
            />
          </div>
        </section>
        <TaskFooter />
      </Form>

      <AddressVerification
        close={closeModal}
        open={modalOpen}
        respond={modalResponse}
        userAddress={userAddress}
        verified={verified}
        verifiedAddress={verifiedAddress}
      />
    </div>
  );
};

Personal.propTypes = {
  match: PropTypes.object,
};

export default Personal;
