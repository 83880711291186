const filterData = (value, path) => {
  const phone = 'personalInfo.contact.phone';
  const SSN = 'personalInfo.PII.SSN';
  const DOB = 'personalInfo.PII.DOB';
  const ECphone = 'personalInfo.contact.emergency.0.phone';

  if (path === phone || path === SSN || path === DOB || path === ECphone) {
    value = value.toString().replace(/\D+/g, '');
  }

  return { value, path };
};

export default {
  filterData,
};
