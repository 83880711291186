import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './formComponents.scss';

class Checkbox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      message: '',
    };
    this.checkSelf = this.checkSelf.bind(this);
  }

  componentDidMount() {
    if (this.context.form) {
      const form = document.forms[this.context.form];

      if (form.addEventListener) {
        form.addEventListener(
          'submit',
          () => {
            this.context.validateForm(this.checkSelf);
          },
          false,
        );
      }
    }
  }

  checkSelf() {
    if (this.context.form) {
      return {
        element: this.props.id,
        validated: true,
      };
    }

    return null;
  }

  render() {
    return (
      <div className="formComponent" onBlur={this.checkSelf}>
        <label htmlFor={this.props.id}>{this.props.label}</label>
        <div className="checkbox-wrapper">
          <input type="checkbox" id={this.props.id} className="checkbox" onClick={this.props.onChange} defaultChecked={this.props.value} />
          {this.props.value && (
            <label id="checkbox-check" htmlFor={this.props.id}>
              <svg width="16px" height="12px" viewBox="0 0 16 12">
                <path d="M15.455 2.103L13.253 0l-7.99 7.617L2.202 4.7 0 6.8l5.264 5.018 10.19-9.715" fill="#000" fillRule="evenodd" />
              </svg>
            </label>
          )}
        </div>
        <div className="invalid width200">{this.state.message}</div>
      </div>
    );
  }
}

Checkbox.propTypes = {
  label: PropTypes.string,
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

Checkbox.contextTypes = {
  validateForm: PropTypes.func,
  form: PropTypes.string,
};

export default Checkbox;
