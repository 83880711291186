import React, { createContext, useEffect, useReducer } from 'react';

import { candidateService } from '../services';
import { candidateUtil } from '../utils';

const initialState = {
  candidate: {
    _id: '',
    workflowData: [{ tasks: [] }],
    admin: {
      assignment: {
        office: {
          name: '',
        },
      },
    },
    personalInfo: {},
    photo: { url: '' },
  },
  candidateUpdated: '',
  currentTask: {
    data: [],
    analytics: {},
  },
  nextTaskUrl: '',
};

export const AppStateContext = createContext();

const reducer = (state, action) => {
  switch (action.type) {
    case 'RETRIEVE_CANDIDATE':
      return {
        ...state,
        candidate: action.candidate,
        currentTask: action.currentTask,
        nextTaskUrl: action.nextTaskUrl,
      };
    case 'UPDATE_CANDIDATE':
      return {
        ...state,
        candidate: { ...state.candidate, ...action.candidate },
        candidateUpdated: new Date(),
      };
    default:
      throw new Error('No action type provided');
  }
};

const AppStateContextProvider = props => {
  const [appState, dispatch] = useReducer(reducer, initialState);
  const { candidateUpdated } = appState;

  const retrieveCandidateEffect = ({ history, match: { path, params } }) => {
    useEffect(() => {
      if (!path) {
        return;
      }

      const retrieveCandidate = async () => {
        try {
          const candidate = await candidateService.getCandidate(params.candidateId);
          const currentTask = candidateUtil.getCurrentTask(candidate);
          const nextTaskUrl = candidateUtil.getNextTaskURL(candidate, currentTask._id);

          if (!currentTask.taskTitle) {
            currentTask.taskTitle = candidateUtil.cleanString(currentTask.sectionKey);
          }

          dispatch({
            type: 'RETRIEVE_CANDIDATE',
            candidate,
            currentTask,
            nextTaskUrl,
          });
        } catch (error) {
          console.error(error);
          history.push(`/${params.candidateId}/login`);
        }
      };

      retrieveCandidate();
    }, [params.candidateId, candidateUpdated]);
  };

  const updateCandidate = async (candidate, message, type) => {
    try {
      if (candidate !== 'Already time-stamped') {
        await candidateService.updateCandidate(candidate, message, type);
        dispatch({ type: 'UPDATE_CANDIDATE', candidate });
      }
    } catch (error) {
      console.log('error:', error);
      throw error;
    }
  };

  const value = { ...appState, retrieveCandidateEffect, updateCandidate };

  return <AppStateContext.Provider value={value}>{props.children}</AppStateContext.Provider>;
};

export default AppStateContextProvider;
