import { alertUtil } from '../utils';
import igniteAPI from './axios';

const createTrackerCandidate = async id => {
  try {
    return await igniteAPI.post(`/candidate/${id}/tracker`, {});
  } catch (error) {
    alertUtil.error('There was an error creating your I-9.', error.message);
    throw error;
  }
};

const getTrackerCandidate = async id => {
  try {
    return await igniteAPI.get(`/candidate/${id}/tracker`);
  } catch (error) {
    alertUtil.error('There was an error retrieving your I-9 data.', error.message);
    throw error;
  }
};

const getTrackerURL = async id => {
  try {
    return await igniteAPI.get(`/candidate/${id}/tracker/url`);
  } catch (error) {
    alertUtil.error('There was an error retrieving your I-9 url.', error.message);
    throw error;
  }
};

const getTrackerStatus = async id => {
  try {
    return await igniteAPI.get(`/candidate/${id}/tracker/status`);
  } catch (error) {
    alertUtil.error('There was an error getting your I-9 status.', error.message);
    throw error;
  }
};

export default {
  createTrackerCandidate,
  getTrackerCandidate,
  getTrackerURL,
  getTrackerStatus,
};
