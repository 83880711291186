/* eslint-disable camelcase */
import agreements from './agreements';
import background_check from './background_check';
import complete from './complete';
import personal from './personal';
import policies from './policies';
import uploadPhoto from './uploadPhoto';
import worker_eligibility from './worker_eligibility';

const index = {
  agreements,
  background_check,
  complete,
  personal,
  policies,
  uploadPhoto,
  worker_eligibility,
};

export default index;
