import igniteAPI from './axios';

const getVicTigUrl = async id => {
  try {
    return await igniteAPI.get(`/background/${id}/invite`);
  } catch (error) {
    return igniteAPI.get(`/background/${id}/start`);
  }
};

export default {
  getVicTigUrl,
};
