import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './formComponents.scss';

class Select extends Component {
  constructor(props) {
    super(props);
    this.state = {
      message: '',
    };
    this.checkSelf = this.checkSelf.bind(this);
  }

  componentDidMount() {
    if (this.context.form) {
      const form = document.forms[this.context.form];

      if (form.addEventListener) {
        form.addEventListener(
          'submit',
          event => {
            event.preventDefault();
            this.context.validateForm(this.checkSelf);
          },
          false,
        );
      }
    }
  }

  checkSelf() {
    if (this.context.form) {
      const { value } = this.props;
      const { validated, message } = this.props.validate(value, this.props.optional);

      if (validated) {
        this.setState({
          message: '',
        });
      } else if (message) {
        this.setState({
          message,
        });
      }

      return {
        element: this.props.id,
        validated,
      };
    }
  }

  render() {
    const width = this.props.width || '200px';
    const { options } = this.props;
    let setDefault = true;
    const optionElements = options.map((item, i) => {
      if (!item.value) setDefault = false;

      return (
        <option key={i} value={item.value}>
          {item.label}
        </option>
      );
    });

    if (setDefault) {
      optionElements.unshift(<option value="">Select One</option>);
    }

    return (
      <div className="formComponent" onBlur={this.checkSelf}>
        <label htmlFor={this.props.id}>{this.props.label}</label>
        <select style={{ width }} id={this.props.id} value={this.props.value || ''} onChange={this.props.onChange}>
          {optionElements}
        </select>
        <div className="invalid">{this.state.message}</div>
      </div>
    );
  }
}

Select.propTypes = {
  label: PropTypes.string,
  width: PropTypes.string,
  id: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  validate: PropTypes.func,
};

Select.contextTypes = {
  validateForm: PropTypes.func,
  form: PropTypes.string,
};

export default Select;
