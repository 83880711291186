import { dateUtil } from '../../utils';

function dob(value) {
  if (!value) {
    return {
      validated: false,
      message: 'Please fill out this field',
    };
  }

  if (new Date(value).toString() === 'Invalid Date') {
    return {
      validated: false,
      message: 'Please enter a valid date of birth.',
    };
  }

  if (dateUtil.compareAge(value, 17, value.split('/').length > 1 ? 'MM/dd/yyyy' : '')) {
    return {
      validated: false,
      message: 'Candidate must be older than 17',
    };
  }

  return {
    validated: true,
    message: null,
  };
}

function legalName(value, optional) {
  const legalNamePattern = /[^a-z ]/gi;

  if (!optional && !value) {
    return {
      validated: false,
      message: 'Please fill out this field',
    };
  }

  if (legalNamePattern.test(value)) {
    return {
      validated: false,
      message: 'Name can only contain non punctuated letters.',
    };
  }

  if (value && value.trim() !== value) {
    return {
      validated: false,
      message: 'Name cannot contain leading or trailing spaces',
    };
  }

  return {
    validated: true,
    message: null,
  };
}

function phone(value) {
  let phoneValue = value;
  const PhonePattern = /^(\(\d{3}\)\s*|\d{3}-)\d{3}-\d{4}$/;

  if (typeof phoneValue === 'number') {
    phoneValue = phoneValue.toString().replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
  }

  if (!phoneValue) {
    return {
      validated: false,
      message: 'Please fill out this field',
    };
  }

  if (!PhonePattern.test(phoneValue)) {
    return {
      validated: false,
      message: 'Please enter a valid Phone Number.',
    };
  }

  return {
    validated: true,
    message: null,
  };
}

function ssn(value) {
  const ssnPattern = /^\d{3}-?\d{2}-?\d{4}$/;

  if (!value) {
    return {
      validated: false,
      message: 'Please fill out this field',
    };
  }

  if (!ssnPattern.test(value)) {
    return {
      validated: false,
      message: 'Enter a valid Social Security Number',
    };
  }

  return {
    validated: true,
    message: null,
  };
}

function zipCode(value) {
  const zipPattern = /^\d{5}(?:[-\s]\d{4})?$/;

  if (!value) {
    return {
      validated: false,
      message: 'Please fill out this field',
    };
  }

  if (!zipPattern.test(value)) {
    return {
      validated: false,
      message: 'Enter a valid Zip Code',
    };
  }

  return {
    validated: true,
    message: null,
  };
}

function email(value) {
  const emailPattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[(?:\d{1,3}\.){3}\d{1,3}])|(([a-zA-Z\-\d]+\.)+[a-zA-Z]{2,}))$/;

  if (!value) {
    return {
      validated: false,
      message: 'Please fill out this field',
    };
  }

  if (!emailPattern.test(value)) {
    return {
      validated: false,
      message: 'Please enter a valid Email',
    };
  }

  return {
    validated: true,
    message: null,
  };
}

function text(value, optional) {
  if (optional) {
    return {
      validated: true,
      message: null,
    };
  }

  if (!value) {
    return {
      validated: false,
      message: 'Please fill out this field',
    };
  }

  return {
    validated: true,
    message: null,
  };
}

function select(value, optional) {
  if (optional) {
    return {
      validated: true,
      message: null,
    };
  }

  if (!value) {
    return {
      validated: false,
      message: 'Please fill out this field',
    };
  }

  return {
    validated: true,
    message: null,
  };
}

export default {
  dob,
  legalName,
  phone,
  ssn,
  zipCode,
  email,
  text,
  select,
};
