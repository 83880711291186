import React from 'react';
import { bannerHomeSVG, sunrunLogoSVG } from '../../../img';
import './BodyHeader.scss';

const BodyHeader = () => {
  return (
    <div className="body-header-wrapper">
      <div className="body-header-banner">
        <img src={bannerHomeSVG} alt="" />
      </div>
      <div className="body-header-logo">
        <h1>Welcome to</h1>
        <img src={sunrunLogoSVG} alt="" />
      </div>
    </div>
  );
};

export default BodyHeader;
