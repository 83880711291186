import React from 'react';

const agreements = ({ color, height, width }) => {
  return (
    <svg height={height || '24px'} width={width || '24px'} viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <title>ic_check_circle_24px</title>
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Artboard" transform="translate(-59.000000, -34.000000)">
          <g id="Group-3-Copy-2" transform="translate(29.000000, 0.000000)">
            <g id="Group-5" transform="translate(6.000000, 34.000000)">
              <g id="ic_check_circle_24px" transform="translate(24.000000, 0.000000)">
                <rect id="Rectangle" x="0" y="0" width="24" height="24"></rect>
                <g id="Group" transform="translate(2.000000, 2.000000)" fill={color || '#fff'} fillRule="nonzero">
                  <path
                    d="M10,0 C4.48,0 0,4.48 0,10 C0,15.52 4.48,20 10,20 C15.52,20 20,15.52 20,10 C20,4.48 15.52,0 10,0 Z M8,15 L3,10 L4.41,8.59 L8,12.17 L15.59,4.58 L17,6 L8,15 Z"
                    id="Shape"
                  ></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default agreements;
