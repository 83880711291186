import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { candidateService } from '../services';
import { alertUtil } from '../utils';
import { userCircleWhiteSVG, sunrunLogoWhiteSVG } from '../../img';

const Login = props => {
  const {
    match: {
      params: { candidateId },
    },
    location: { search },
  } = props;
  const [localState, setLocal] = useState({
    email: search.split('email=')[1] || '',
    unsubscribe: false,
  });

  useEffect(() => {
    const { unsubscribe } = props.match.params;

    if (unsubscribe && unsubscribe !== 'unsubscribe') {
      return props.history.push(`/${this.props.match.params.candidateId}/login`);
    }

    if (unsubscribe && unsubscribe === 'unsubscribe') {
      setLocal({ ...localState, unsubscribe: true });
    }
  });

  const verify = () => {
    if (!localState.email) {
      return {
        verified: false,
        message: 'Please enter a valid email',
      };
    }

    if (!localState.email.includes('@')) {
      return {
        verified: false,
        message: 'Please include an "@" with your email',
      };
    }

    return {
      verified: true,
      message: null,
    };
  };

  const handleSubmit = async event => {
    if (event) event.preventDefault();

    const { verified, message } = verify();

    if (verified) {
      try {
        const response = await candidateService.login(candidateId, localState.email);

        if (response.accessToken && response.user.type === 'candidate' && response.user.providerId === candidateId) {
          return props.history.push(`/${candidateId}/${localState.unsubscribe ? 'unsubscribe' : 'verify'}`);
        }
      } catch (error) {
        console.error(error);
      }
    }

    return alertUtil.info(message || 'Invalid login credentials');
  };

  const handleInputChange = event => {
    setLocal({
      ...localState,
      email: event.target.value,
    });
  };

  return (
    <form onSubmit={handleSubmit} className="login-wrapper">
      <div className="logo">
        <img src={sunrunLogoWhiteSVG} alt="Sunrun" className="login-logo" />
        <div className="login-title">Onboarding Portal</div>
      </div>

      <div className="login-body">
        <img src={userCircleWhiteSVG} alt="Sunrun" className="login-logo" />

        <div className="input-align">
          <input required type="email" className="login-text-box" placeholder="Email" value={localState.email} onChange={handleInputChange} />
          <button type="submit" className="go">
            <span>GO!</span>
          </button>
        </div>
      </div>

      <div className="login-info">Please verify the email address you provided.</div>
    </form>
  );
};

Login.propTypes = {
  match: PropTypes.object,
  history: PropTypes.object,
};

export default withRouter(Login);
