import React, { useContext, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import get from 'lodash.get';

import { AppStateContext } from '../context/StateContext';
import { candidateUtil } from '../utils';
import Header from '../components/header/Header';
import EligibilityModal from '../components/modal/eligibilityModal/EligibilityModal';
import ProgressNav from '../components/progressNav/ProgressNav';
import LoadingAnimation from '../components/loadingAnimation/LoadingAnimation';
import Tasks from './tasks/Tasks';

const OnboardPortal = props => {
  const { candidate, currentTask = {}, candidateUpdated, retrieveCandidateEffect, updateCandidate } = useContext(AppStateContext);

  // Load the candidate into the state context
  retrieveCandidateEffect(props);

  // If the candidate is coming back from filling out their background check, mark as form complete
  useEffect(() => {
    if (props.match.params.section === 'setStatus' && candidate._id) {
      const { tasks } = candidateUtil.getWorkflow(candidate);

      tasks.find(task => {
        return task.taskType === 'background_check';
      }).status = 'Form Complete';

      updateCandidate(candidate);
    }
  }, [candidate._id]);

  // If the candidate has verified their info, continue. Otherwise send to verification
  useEffect(() => {
    const { _id, analytics, sectionKey, taskTitle, taskType } = currentTask;

    const markTaskStartDate = async () => {
      try {
        if (taskType !== 'tracker_i9') {
          const temporaryCandidate = candidateUtil.dateStampCandidate(candidate, _id, 'start');

          await updateCandidate(temporaryCandidate, `${taskTitle} Started`);
        }
      } catch (error) {
        console.error(error);
      }
    };

    // Mark the currentTask as started if it is not
    if (get(candidate, '_id') && !get(analytics, 'start') && sectionKey !== 'complete') {
      markTaskStartDate();
    }
  }, [candidate, candidateUpdated, currentTask._id, currentTask.analytics.start]);

  // Render onboarding view
  if (candidate._id) {
    return (
      <div className="onboard-portal">
        <Header candidate={candidate} />
        <div className="onboard-portal-body">
          <ProgressNav candidate={candidate} />
          <Tasks {...props} />
        </div>
        <footer />
        <EligibilityModal eligible={candidate.admin.workdayEligibility} active={candidate.admin.activeInWorkday} />
      </div>
    );
  }

  // Loading screen
  return (
    <div className="onboard-portal">
      <LoadingAnimation />
    </div>
  );
};

OnboardPortal.propTypes = {
  match: PropTypes.object,
  history: PropTypes.object,
};

export default withRouter(OnboardPortal);
