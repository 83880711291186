import React, { useContext } from 'react';
import { AppStateContext } from '../../../context/StateContext';
import { candidateUtil, envUtil } from '../../../utils';
import TaskFooter from '../../../components/taskFooter/TaskFooter';

const Wotc = () => {
  const env = envUtil.getEnv();
  const uri = 'https://secure.talxtci.com/Screening/Demographics/Login';
  const { candidate, currentTask, updateCandidate } = useContext(AppStateContext);

  // Check to see if the document has been completed
  const handleSubmit = async event => {
    event.preventDefault();

    try {
      // If the document is completed, update the candidate
      const auditMessage = `${currentTask.taskTitle} Completed`;
      let temporaryCandidate = candidate;

      temporaryCandidate = candidateUtil.dateStampCandidate(temporaryCandidate, currentTask._id, 'complete');
      updateCandidate(temporaryCandidate, auditMessage, 'docusign');
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <form className="onboarding-wrapper" onSubmit={handleSubmit}>
      <div className="wotc-info-wrapper">
        <div className="wotc-info">
          <p>
            The Work Opportunity Tax Credit (WOTC) is a Federal tax credit available to employers for hiring individuals from certain targeted groups who have
            consistently faced significant barriers to employment. Thank you for taking the time to complete this questionnaire.
          </p>

          <br />
          <b>Employer Code: {env ? '72272' : '72985'}</b>
          <br />
          <b>Employer Location Code: (DEFAULT)</b>
          <br />
          <br />

          <p>Please do not click next at the bottom of your screen until you see "Thank you!"</p>
        </div>
      </div>
      <iframe className="wotc" title="Preemployment WOTC Survey" src={uri} />
      <TaskFooter />
    </form>
  );
};

export default Wotc;
