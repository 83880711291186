import { create } from 'axios';
import get from 'lodash.get';
import { envUtil } from '../utils';

const baseUrl = (() => {
  if (envUtil.getEnv() === 'dev') {
    return 'http://localhost.dev.vivintsolar.com:5000';
  }

  return `https://hr-api.${envUtil.getEnv() === 'stage' ? 'stage.' : ''}vivintsolar.com`;
})();

const responseInterceptor = response => get(response, 'data', response);
const errorInterceptor = error => Promise.reject(new Error(get(error, 'response.data.error', error.message)));

const igniteAPI = create({
  baseURL: baseUrl,
  withCredentials: true,
  headers: {
    'x-client': 'onboarding',
  },
});

igniteAPI.interceptors.response.use(responseInterceptor, errorInterceptor);

export default igniteAPI;
