import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  RED,
  GREEN,
  DARK_GRAY,
  LIGHT_GRAY,
  BORDER_GRAY,
  WHITE,
  BORDER_RADIUS,
  ELEMENT_PADDING,
  INNER_PADDING,
  LABEL_SIZE,
  FONT_SIZE,
  FONT,
} from '../web-vs-style/src';

class VSInput extends Component {
  constructor() {
    super();
    this.state = {
      message: '',
    };
    this.checkSelf = this.checkSelf.bind(this);
  }

  borderState() {
    if (this.props.disable) {
      return `1px dashed ${LIGHT_GRAY}`;
    }

    if (this.props.active) {
      return `2px solid ${GREEN}`;
    }

    if (this.state.message) {
      return `2px solid ${RED}`;
    }

    return `1px solid ${BORDER_GRAY}`;
  }

  labelState() {
    if (this.props.active) {
      return GREEN;
    }

    if (this.state.message) {
      return RED;
    }

    if (this.props.dark) {
      return WHITE;
    }

    return DARK_GRAY;
  }

  widthState() {
    const { size } = this.props;

    if (size === 'large') {
      return '544px';
    }

    if (size === 'medium') {
      return '256px';
    }

    if (size === 'small') {
      return '160px';
    }
  }

  heightState() {
    if (this.props.size) {
      return '48px';
    }
  }

  componentDidMount() {
    if (this.context.form) {
      const form = document.forms[this.context.form];

      if (form.addEventListener) {
        form.addEventListener(
          'submit',
          event => {
            event.preventDefault();
            this.context.validateForm(this.checkSelf);
          },
          false,
        );
      }
    }
  }

  checkSelf() {
    if (this.context.form) {
      const { value } = this.props;
      const { validated, message } = this.props.validate(value, this.props.optional);

      if (validated) {
        this.setState({
          message: '',
        });
      } else if (message) {
        this.setState({
          message,
        });
      }

      return {
        element: this.props.id,
        validated,
      };
    }

    return null;
  }

  render() {
    const { label, name, className, onChange, id, value } = this.props;

    const styles = {
      wrapper: {
        display: 'grid',
      },
      input: {
        width: this.widthState(),
        height: this.heightState(),
        borderRadius: BORDER_RADIUS,
        marginBottom: ELEMENT_PADDING,
        marginTop: ELEMENT_PADDING,
        padding: INNER_PADDING,
        border: this.borderState(),
        backgroundColor: 'transparent',
        fontSize: FONT_SIZE,
      },
      label: {
        color: this.labelState(),
        fontSize: LABEL_SIZE,
        fontFamily: FONT,
        display: 'flex',
      },
      error: {
        color: RED,
        fontSize: LABEL_SIZE,
        fontFamily: FONT,
        display: 'flex',
      },
    };

    return (
      <label htmlFor={name} style={styles.wrapper} onBlur={this.checkSelf}>
        <span style={styles.label}>{label}</span>
        <input id={id} className={className} style={styles.input} type="text" onChange={onChange} value={value} name={name} />
        {this.state.message ? <span style={styles.error}>{this.state.message}</span> : null}
      </label>
    );
  }
}

VSInput.propTypes = {
  error: PropTypes.string,
  disable: PropTypes.bool,
  active: PropTypes.bool,
  dark: PropTypes.bool,
  size: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  className: PropTypes.string,
  onChange: PropTypes.func,
};

VSInput.defaultProps = {
  error: null,
  disable: false,
  active: false,
  label: 'label',
};

VSInput.contextTypes = {
  validateForm: PropTypes.func,
  form: PropTypes.string,
};

export default VSInput;
