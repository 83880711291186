import React, { useContext, useEffect, useState } from 'react';
import get from 'lodash.get';
import { AppStateContext } from '../../../context/StateContext';
import { candidateUtil } from '../../../utils';
import { Form, validate } from '../../../components/reactForms';
import BodyHeader from '../../../components/bodyHeader/BodyHeader';
import VSInput from '../../../components/vs-input/src';

const getLocalContext = candidate => {
  return {
    firstName: {
      path: 'firstName',
      value: get(candidate, 'firstName', ''),
    },
    middleName: {
      path: 'middleName',
      value: get(candidate, 'middleName', ''),
    },
    lastName: {
      path: 'lastName',
      value: get(candidate, 'lastName', ''),
    },
    preferredName: {
      path: 'preferredName',
      value: get(candidate, 'preferredName', ''),
    },
    email: {
      path: 'personalInfo.email',
      value: get(candidate, 'personalInfo.email', ''),
    },
  };
};

const Verify = props => {
  const { candidate, currentTask, updateCandidate } = useContext(AppStateContext);
  const [localState, setLocal] = useState(getLocalContext(candidate));

  useEffect(() => {
    setLocal(getLocalContext(candidate));
  }, [candidate._id]);

  const handleInputChange = event => {
    event.preventDefault();
    const { id: key, value } = event.target;
    const state = { ...localState };

    state[key].value = value;

    setLocal(state);
  };

  const handleSubmit = async result => {
    const { validated, validationError } = result;
    let temporaryCandidate = { ...candidate };

    if (validated) {
      try {
        // Mark candidate as verified and update bassed on inputs
        temporaryCandidate.personalInfo.verified = new Date();
        temporaryCandidate = candidateUtil.applyFormInputs(temporaryCandidate, localState);
        await updateCandidate(temporaryCandidate, 'Candidate Verified');

        return props.history.push(`/${candidate._id}/${currentTask.taskTitle}`);
      } catch (error) {
        console.error(error);
      }
    }

    return console.warn(validationError);
  };

  return (
    <div className="verify-task">
      <BodyHeader />

      <div className="verify-welcome">
        <h1>{`Welcome, ${candidate.firstName}!`}</h1>
        <h2>Let's get started with signing your offer and other important documents</h2>
        <h3>Please verify your information</h3>
      </div>

      <div className="verify-input">
        <Form name="Verify" onSubmit={handleSubmit} state={localState}>
          <section>
            <div style={{ padding: '5px 10px' }}>
              <VSInput label="Legal First Name" id="firstName" validate={validate.legalName} onChange={handleInputChange} value={localState.firstName.value} />
            </div>
            <div style={{ padding: '5px 10px' }}>
              <VSInput
                label="Legal Middle Name"
                id="middleName"
                validate={validate.legalName}
                onChange={handleInputChange}
                value={localState.middleName.value}
                optional="true"
              />
            </div>
            <div style={{ padding: '5px 10px' }}>
              <VSInput label="Legal Last Name" id="lastName" validate={validate.legalName} onChange={handleInputChange} value={localState.lastName.value} />
            </div>
            <div style={{ padding: '5px 10px' }}>
              <VSInput
                label="Preferred First Name"
                id="preferredName"
                validate={validate.text}
                onChange={handleInputChange}
                value={localState.preferredName.value}
                optional="true"
              />
            </div>
            <div className="verify-email" style={{ padding: '5px 10px' }}>
              <VSInput label="Email Address" id="email" validate={validate.email} onChange={handleInputChange} value={localState.email.value} />
            </div>
          </section>

          <button className="verify-button" type="submit">
            <div>Verify!</div>
          </button>
        </Form>
      </div>
    </div>
  );
};

export default Verify;
