import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { AppStateContext } from '../../context/StateContext';
import Docusign from './taskTypes/Docusign';
import Personal from './taskTypes/Personal';
import TrackerV2 from './taskTypes/TrackerV2';
import Verify from './taskTypes/Verify';
import Victig from './taskTypes/Victig';
import Wotc from './taskTypes/WOTC';

const Tasks = props => {
  const { candidate, currentTask } = useContext(AppStateContext);
  const { match, history } = props;
  const { taskType, taskTitle, sectionKey } = currentTask;

  // If the candidate has verified their info, continue. Otherwise send to verification
  useEffect(() => {
    const { verified } = candidate.personalInfo;
    const { taskTitle } = currentTask;

    history.push(`/${candidate._id}/${verified ? taskTitle : 'verify'}`);
  }, [currentTask._id]);

  // Display taskless views when the section is requested
  if (match.params.section === 'verify') {
    return <Verify {...props} />;
  }

  if (match.params.section === 'complete' || sectionKey === 'complete') {
    return <TrackerV2 {...props} />;
  }

  // Render given task based on task type
  const componentList = {
    docusign: props => <Docusign {...props} />,
    form: props => <Personal {...props} />,
    background_check: props => <Victig {...props} />, // eslint-disable-line camelcase
    tracker_i9: props => <TrackerV2 {...props} />, // eslint-disable-line camelcase
    wotc: props => <Wotc {...props} />,
  };
  const createComponent = componentList[taskType];

  return (
    <div className="tasks-wrapper">
      <div className="Task">
        <div className="Task-header">
          <div>
            <h1>{taskTitle}</h1>
          </div>
        </div>
        <div className="Task-body">{createComponent && createComponent(props)}</div>
      </div>
    </div>
  );
};

Tasks.propTypes = {
  match: PropTypes.object,
  history: PropTypes.object,
};

export default Tasks;
