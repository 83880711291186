const buildFullAddress = ({ streetLine1, streetLine2, city, state, zip, country }) => {
  zip = zip.split('-')[0];
  const stateCheck = state.split('-');

  if (stateCheck.length > 1) {
    state = stateCheck[1];
  }

  const addressLine = [streetLine1, streetLine2].filter(Boolean).join(' ');

  return [addressLine, city, `${state} ${zip}`, country].filter(Boolean).join(', ').trim().toUpperCase();
};

export default {
  buildFullAddress,
};
