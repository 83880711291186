import React from 'react';

const LoadingAnimation = () => {
  return (
    <div className="loading-animation">
      <div>
        Igniting <span>.</span>
        <span>.</span>
        <span>.</span>
      </div>
    </div>
  );
};

export default LoadingAnimation;
