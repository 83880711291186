const getEnv = function () {
  const { hostname } = window.location;

  if (hostname.split('.')[1] === 'stage') {
    return 'stage';
  }

  if (hostname.split('.')[1] === 'dev') {
    return 'dev';
  }

  return '';
};

export default {
  getEnv,
};
