import React from 'react';

const TaskFooter = props => {
  const submit = event => {
    if (props.submit) {
      props.submit(event);
    }
  };

  return (
    <div className="Task-footer">
      {props.helpText && <div className="help-text">{props.helpText}</div>}
      <button type="submit" className="nextButton" onClick={submit}>
        {props.checking ? 'Checking...' : 'Next'}
      </button>
    </div>
  );
};

export default TaskFooter;
