import { alertUtil } from '../utils';
import igniteAPI from './axios';

/* Returns a signedUrl from Docusign given a candidateid and envelopeId */
const getSignedDocusignUrl = async (candidateId, envelopeId) => {
  try {
    return await igniteAPI.get(`/docusign/${envelopeId}/url?candidateId=${candidateId}`);
  } catch (error) {
    alertUtil.error('There was an error getting your docusign data.', error.message);
    throw error;
  }
};

/* Returns a signedUrl from Docusign given a candidateid and envelopeId */
const getEnvelope = async (candidateId, taskId) => {
  try {
    return await igniteAPI.get(`/docusign/envelope?candidateId=${candidateId}&taskId=${taskId}`);
  } catch (error) {
    alertUtil.error('There was an error getting your docusign envelope.', error.message);
    throw error;
  }
};

const voidEnvelope = async (candidateId, taskId) => {
  try {
    return await igniteAPI.put(`/docusign/envelope?candidateId=${candidateId}&taskId=${taskId}`, {});
  } catch (error) {
    alertUtil.error('There was an error clearing an old document.', error.message);
    throw error;
  }
};

/* Returns the status of an envelope */
const getEnvelopeStatus = async envelopeId => {
  try {
    return await igniteAPI.get(`/docusign/${envelopeId}/status`);
  } catch (error) {
    alertUtil.error('There was an error getting your document status.', error.message);
    throw error;
  }
};

export default {
  getSignedDocusignUrl,
  getEnvelope,
  voidEnvelope,
  getEnvelopeStatus,
};
