import React, { useContext, useEffect, useState } from 'react';
import { AppStateContext } from '../../../context/StateContext';
import { trackerService } from '../../../services';
import LoadingAnimation from '../../../components/loadingAnimation/LoadingAnimation';
import Complete from './Complete';

const Tracker = props => {
  const { candidate } = useContext(AppStateContext);
  const [localState, setLocal] = useState({
    loading: true,
    uri: '',
    checking: false,
    submitType: '',
  });
  const { loading, uri } = localState;

  useEffect(() => {
    const retrieveTrackerURL = async () => {
      let status = { URI: '' };
      try {
        // Check the current status
        status = (await trackerService.getTrackerStatus(candidate._id)) || {};

        // If there is no status create a new tracker candidate
        if (!status.URI && !status.nextStep) {
          status = (await trackerService.createTrackerCandidate(candidate._id)) || {};
        }
      } catch (error) {
        console.error(error);
      }

      // Reset all values and update status on the local state
      setLocal({
        ...localState,
        uri: status.URI,
        loading: false,
      });
    };

    if (candidate._id) {
      retrieveTrackerURL();
    }
  }, [candidate._id]);

  if (loading) {
    return <LoadingAnimation />;
  }

  return <Complete {...props} noBackdrop uri={uri} />;
};

export default Tracker;
