import React from 'react';
import { sunrunLogoSVG } from '../../../img';

const Header = props => (
  <section className="header-wrapper">
    <img src={sunrunLogoSVG} alt="" className="header-logo" />
  </section>
);

export default Header;
