import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import OnboardPortal from './views/OboardPortal';
import Login from './views/Login';
import Unsubscribe from './views/Unsubscribe';
import StatusPage from './views/StatusPage';
import Alert from './components/alert/Alert';
import AppStateContextProvider from './context/StateContext';

const NotFound = () => {
  return <div>PAGE NOT FOUND</div>;
};

const Unauthorized = () => {
  return <div>You are not authorized to use this application</div>;
};

const error = () => {
  return <div>There was a problem in the server</div>;
};

const App = () => {
  return (
    <AppStateContextProvider>
      <div>
        <Router>
          <Switch>
            <Route exact path="/:candidateId/login/:unsubscribe?" component={Login} />
            <Route path="/:candidateId/unsubscribe" component={Unsubscribe} />
            <Route path="/:candidateId/:section" component={OnboardPortal} />
            {/* --------- */}
            <Route exact path="/401" component={Unauthorized} />
            <Route exact path="/500" component={error} />
            <Route exact path="/continue" component={StatusPage} />
            <Route component={NotFound} />
            {/* delete all the things and put StatusPage */}
          </Switch>
        </Router>
        <Alert />
      </div>
    </AppStateContextProvider>
  );
};

export default App;
