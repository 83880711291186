import React from 'react';
import PropTypes from 'prop-types';
import './modal.scss';

const modal = props => {
  const close = props.close || (() => {});

  return (
    <div>
      {props.open && (
        <div className="modal">
          <div className="modal-backdrop" onClick={close} />
          <div className="modal-body">
            <div className="modal-header">
              <h1>{props.title}</h1>
            </div>

            <div className="modal-content">{props.children}</div>
          </div>
        </div>
      )}
    </div>
  );
};

modal.propTypes = {
  children: PropTypes.any,
  close: PropTypes.func,
  open: PropTypes.bool,
  title: PropTypes.string,
};

export default modal;
