import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { candidateService } from '../services';
import { AppStateContext } from '../context/StateContext';
import { sunrunLogoWhiteSVG } from '../../img';

const Unsubscribe = props => {
  const { candidate, updateCandidate, retrieveCandidateEffect } = useContext(AppStateContext);

  retrieveCandidateEffect(props);

  const changeSubscription = async () => {
    const { email } = candidate.notifications;

    candidate.notifications.email = !email;

    try {
      await candidateService.updateCandidate(candidate, `${email ? 'Unsubscribed from' : 'Subscribed to'} notifications`, `${email ? 'un' : ''}subscribed`);

      updateCandidate(candidate, 'Candidate unsubscribed from emails');
    } catch (error) {
      console.log('error:', error);
    }
  };

  return (
    <div className="unsubscribe-wrapper">
      <div>
        <img src={sunrunLogoWhiteSVG} alt="Sunrun" className="unsubscribe-logo" />
        <div className="unsubscribe-title">Onboarding Portal</div>
      </div>

      {candidate.notifications && candidate.notifications.email && (
        <div className="unsubscribe-text-wrapper">
          You are attempting to unsubscribe from email notifications. Are you sure you would like to proceed?
          <button className="unsubscribe-button" onClick={changeSubscription}>
            Unsubscribe
          </button>
        </div>
      )}

      {candidate.notifications && !candidate.notifications.email && (
        <div className="unsubscribe-text-wrapper">
          You have successfully unsubscribed.
          <button className="unsubscribe-button" onClick={changeSubscription}>
            Resubscribe
          </button>
        </div>
      )}
    </div>
  );
};

Unsubscribe.propTypes = {
  match: PropTypes.object,
  history: PropTypes.object,
};

export default Unsubscribe;
