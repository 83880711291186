import React from 'react';

const agreements = ({ color, height, width }) => {
  return (
    <svg height={height || '24px'} width={width || '24px'} viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <title>description-24px (1)</title>
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Artboard" transform="translate(-200.000000, -34.000000)">
          <g id="Group-3-Copy-3" transform="translate(170.000000, 0.000000)">
            <g id="Group-5" transform="translate(6.000000, 34.000000)">
              <g id="description-24px-(1)" transform="translate(24.000000, 0.000000)">
                <polygon id="Path" points="0 0 24 0 24 24 0 24"></polygon>
                <path
                  d="M14,2 L6,2 C4.9,2 4.01,2.9 4.01,4 L4,20 C4,21.1 4.89,22 5.99,22 L18,22 C19.1,22 20,21.1 20,20 L20,8 L14,2 Z M16,18 L8,18 L8,16 L16,16 L16,18 Z M16,14 L8,14 L8,12 L16,12 L16,14 Z M13,9 L13,3.5 L18.5,9 L13,9 Z"
                  id="Shape"
                  fill={color || '#fff'}
                  fillRule="nonzero"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default agreements;
