import React from 'react';

const Personal = ({ color, height, width }) => {
  return (
    <svg height={height || '24px'} width={width || '24px'} viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <title>person-24px</title>
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Artboard" transform="translate(-200.000000, -266.000000)">
          <g id="Group-3-Copy-3" transform="translate(170.000000, 0.000000)">
            <g id="Group-4" transform="translate(16.000000, 266.000000)">
              <g id="person-24px" transform="translate(14.000000, 0.000000)">
                <polygon id="Path" points="0 0 24 0 24 24 0 24"></polygon>
                <path
                  d="M12,12 C14.21,12 16,10.21 16,8 C16,5.79 14.21,4 12,4 C9.79,4 8,5.79 8,8 C8,10.21 9.79,12 12,12 Z M12,14 C9.33,14 4,15.34 4,18 L4,20 L20,20 L20,18 C20,15.34 14.67,14 12,14 Z"
                  id="Shape"
                  fill={color || '#fff'}
                  fillRule="nonzero"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default Personal;
