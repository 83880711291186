import React from 'react';
import PropTypes from 'prop-types';

const CloseX = ({ fill }) => {
  return (
    <svg width="10" height="10" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.26 9.183l-3.67-3.67-3.668 3.67L0 8.26l3.67-3.67L0 .923.922 0l3.67 3.67L8.26 0l.92.922-3.67 3.67 3.67 3.67" fillRule="nonzero" fill={fill} />
    </svg>
  );
};

CloseX.propTypes = {
  fill: PropTypes.string,
};

CloseX.defaultProps = {
  fill: '#51C0EA',
};

export default CloseX;
