import React, { useContext, useEffect, useState } from 'react';
import StatusPage from '../../StatusPage';
import TaskFooter from '../../../components/taskFooter/TaskFooter';
import { vicTigService } from '../../../services';
import { alertUtil } from '../../../utils';
import { AppStateContext } from '../../../context/StateContext';

const Victig = props => {
  const { candidate, currentTask, nextTaskUrl } = useContext(AppStateContext);
  const [localState, setLocal] = useState({ complete: null, inviteUrl: null });
  const { complete, inviteUrl } = localState;

  useEffect(() => {
    const { _id } = candidate;

    if (currentTask) {
      setLocal({ ...localState, complete: Boolean(currentTask.status) });
    }

    if (_id) {
      (async () => {
        try {
          const response = await vicTigService.getVicTigUrl(_id);

          setLocal({ ...localState, inviteUrl: response.inviteUrl });
        } catch (error) {
          alertUtil.error(`Could not generate a background check.`, error);
        }
      })();
    }
  }, [candidate._id]);

  const continueToNextTask = () => {
    props.history.push(nextTaskUrl);
  };

  const handleSubmit = event => {
    event.preventDefault();

    if (currentTask.status) {
      return continueToNextTask();
    }

    return window.dispatchEvent(
      new CustomEvent('alert', {
        detail: {
          type: 'warning',
          message: "Please click on 'Start Background' and finish the background check before continuing.",
        },
      }),
    );
  };

  const handleStart = () => {
    window.location = inviteUrl;
  };

  if (complete) {
    return (
      <div style={{ color: '#001a3c' }}>
        <StatusPage />
        <div className="Task-footer">
          <div className="nextButton" onClick={handleSubmit}>
            Next
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="victig-body">
      <div className="victig-container">
        <p>Click the button to start background.</p>
        <p>You should be redirected back here when done</p>
        <button className="victig-button" onClick={handleStart}>
          Start Background
        </button>
        <p> if you have returned from filling out the form click next</p>
      </div>
      <TaskFooter submit={handleSubmit} />
    </div>
  );
};

export default Victig;
