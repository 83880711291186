import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { addressUtil } from '../../../utils';
import Modal from '../Modal';
import Checkbox from '../../reactForms/Checkbox';
import './addressVerification.scss';

const AddressVerification = props => {
  const [localState, setLocal] = useState({
    useSuggestedAddress: true,
  });
  const { useSuggestedAddress } = localState;
  const { userAddress, verifiedAddress, verified, close } = props;
  const userAddressString = addressUtil.buildFullAddress(userAddress);
  const verifiedAddressString = addressUtil.buildFullAddress(verifiedAddress);

  const submit = () => {
    const { useSuggestedAddress } = localState;
    const { userAddress, verified, verifiedAddress, respond } = props;
    let selectedAddress = verifiedAddress;
    let responseVerification = verified;

    if (!useSuggestedAddress) {
      selectedAddress = userAddress;
      responseVerification = false;
    }

    return respond(selectedAddress, responseVerification);
  };

  const updateSelectedAddress = event => {
    setLocal({
      useSuggestedAddress: event.target.id === 'suggested',
    });
  };

  return (
    <Modal close={close} open={props.open} title="Verify Address">
      {verified && verifiedAddressString && (
        <div className="modal-message">We found an address matching the one you provided. You can use your own, but we recommend using the one provided.</div>
      )}

      {!verified && verifiedAddressString && (
        <div className="modal-message">
          We could not find an address matching the one you provided. Could the suggested address be what you meant to type? Please review your address and make
          sure it is correct before selecting an option to proceed.
        </div>
      )}

      {!verified && !verifiedAddressString && (
        <div className="modal-message">
          We could not find an address matching the one you provided. Please review your address and make sure it is correct before proceeding.
        </div>
      )}

      {verifiedAddressString && (
        <div className="modal-address-display">
          <div className="modal-address--type">
            <h3>Use {verified ? 'Recommended' : 'Suggested'} Address</h3>

            <Checkbox id="suggested" onChange={updateSelectedAddress} value={useSuggestedAddress} />
          </div>

          {verifiedAddressString}
        </div>
      )}

      <div className="modal-address-display">
        <div className="modal-address--type">
          <h3>Use Your Address</h3>

          <Checkbox id="user" onChange={updateSelectedAddress} value={!useSuggestedAddress} />
        </div>

        {userAddressString}
      </div>

      <div className="modal-buttons">
        <button onClick={close}>Cancel</button>
        <button onClick={submit} disabled={useSuggestedAddress && !verifiedAddressString}>
          Submit
        </button>
      </div>
    </Modal>
  );
};

AddressVerification.propTypes = {
  close: PropTypes.func,
  open: PropTypes.bool,
  respond: PropTypes.func,
  userAddress: PropTypes.object,
  verified: PropTypes.bool,
  verifiedAddress: PropTypes.object,
};

export default AddressVerification;
