import set from 'lodash.set';
import get from 'lodash.get';
import filterUtil from './filters';

const orgs = {
  RETAIL: 'RETAIL',
  CORPORATE: 'CORPORATE',
  FIELD: 'FIELD',
  SALES: 'SALES',
};

const orgMapping = [
  candidate => {
    const org = get(candidate, 'prehire.org', '') || '';
    return org.includes('retail') ? orgs.RETAIL : null;
  },
  candidate => {
    const org = get(candidate, 'prehire.org', '') || '';
    return org.includes('corporate') ? orgs.CORPORATE : null;
  },
  candidate => {
    const org = get(candidate, 'prehire.org', '') || '';
    return org.includes('field') ? orgs.FIELD : null;
  },
  candidate => {
    const org = get(candidate, 'prehire.org', '') || '';
    return org.includes('sales') ? orgs.SALES : null;
  },
];

const getWorkflow = candidate => {
  return candidate.workflowData[candidate.workflowData.length - 1];
};

const underscoreRegex = /_/g;
const cleanString = string => {
  if (!string) {
    return null;
  }

  // Trim white space, remove any extra spaces, then uppercase the first letter of every word and return
  return string
    .trim()
    .replace(underscoreRegex, ' ')
    .split(' ')
    .map(([first, ...rest]) => first.toUpperCase() + rest.join('').toLowerCase())
    .join(' ');
};

const checkTaskCompletion = task => {
  const { taskType, status, analytics, data } = task;
  const backgroundComplete = status && taskType === 'background_check';
  const i9Complete = taskType === 'tracker_i9' && data.length > 0 && data[data.length - 1].nextStep === 'Complete Section 2';

  return analytics.complete || backgroundComplete || i9Complete;
};

const getCurrentTask = candidate => {
  const { tasks } = getWorkflow(candidate);

  return tasks.reduce(
    (searchResults, task) => {
      const searchComplete = checkTaskCompletion(searchResults);
      const taskCompletion = checkTaskCompletion(task);

      return searchComplete && !taskCompletion ? task : searchResults;
    },
    {
      _id: '',
      status: 'default',
      sectionKey: 'complete',
      data: [{}],
      analytics: { complete: true },
    },
  );
};

const getNextTaskURL = (candidate, taskId) => {
  const { tasks } = getWorkflow(candidate);
  let nextTask;

  tasks.forEach((task, i) => {
    if (task._id === taskId) {
      nextTask = tasks[i + 1];
    }
  });

  if (nextTask) {
    return `/${candidate._id}/${nextTask.taskTitle}/`;
  }

  return `/${candidate._id}/complete`;
};

const getTaskData = (taskId, candidate) => {
  const workflowData = getWorkflow(candidate);

  return workflowData.tasks.find(task => task._id === taskId);
};

const dateStampCandidate = (candidate, taskId, key) => {
  const selectedTask = getTaskData(taskId, candidate);

  if (!selectedTask) {
    throw new Error(`The candidate has not been retrieved yet`);
  }

  if (selectedTask.analytics[key]) {
    return 'Already time-stamped';
  }

  if (key === 'complete' || key === 'status') {
    selectedTask.status = selectedTask.status || 'Form Complete';
  }

  if (key !== 'status') {
    selectedTask.analytics[key] = new Date();
  }

  return candidate;
};

const updateTaskUri = (candidate, taskId, uri) => {
  const selectedTask = getTaskData(taskId, candidate);

  selectedTask.uri = uri;

  return candidate;
};

const applyFormInputs = (candidate, formData) => {
  for (const element in formData) {
    if (formData.hasOwnProperty(element)) {
      const { value, path } = filterUtil.filterData(formData[element].value, formData[element].path);

      candidate = set(candidate, path, value);

      if (path === 'personalInfo.contact.mailingAddress.verified' && !value) {
        candidate.admin.notes.push({
          message: 'Candidate submited an unverified address. Problems may arise later in hire process.',
          user: {
            name: 'Automated Message',
            id: '00000',
          },
        });
      }
    }
  }

  return candidate;
};

const determineOrg = candidate => {
  let org = '';
  for (const mapping of orgMapping) {
    const isOrg = mapping(candidate);
    if (isOrg) {
      org = isOrg;
      break;
    }
  }
  return org;
};

export default {
  Orginazations: orgs,
  determineOrg,
  getWorkflow,
  cleanString,
  checkTaskCompletion,
  getCurrentTask,
  getNextTaskURL,
  getTaskData,
  dateStampCandidate,
  updateTaskUri,
  applyFormInputs,
};
